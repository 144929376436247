import React, { useState, useEffect } from 'react'
import { useParams, useNavigate,useLocation  } from 'react-router-dom'
import { findProblemName, sendCallBackQueue,serviceCallBack,getCustomerInfo,appSidlogin,getScheduleDate} from '../../../services/Front';
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";
import moment from 'moment'; 
import DatePicker from "react-multi-date-picker";
// import DatePanel from "react-multi-date-picker/plugins/date_panel"

const ServiceEntry = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const params = useParams(); 
    const { search } = useLocation();
    const parameters = new URLSearchParams(search);

    const pid = params.id; 
    const ledgern = parameters.get('ledgern');  
    const [schDateError, setSchDateError] = useState("");
    const [startDate, setStartDate] = useState(""); //
    const [showScheduleDate, setShowScheduleDate] = useState([]); //
    const isWeekday = (date) => {
        const day = date.getDay(date);
        return day !== 0 && day !== 6;
      }; 
    const [problemsName, setProblemsName] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [customerInfo, setCustomerInfo] = useState("");
    const [appSid, setAppSid] = useState("");
    const [checkLedgerNum, setCheckLedgerNum] = useState(false); 

  
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setPhoneNumberError(""); 
         setShow(true)
    };

    // show popup service call
    const [showServicePopup, setShowServicePopup] = useState(false);
    const handleServicePopupClose = () => setShowServicePopup(false);
    const handleServicePopupShow = () => {
        setSchDateError("");
        setShowServicePopup(true)
    }; 
    const handleInput = ({
        target: { value }
    }) => setPhoneNumber(value);

    useEffect(() => {
        getAppSidlogin();
        getScheduleData();
        findProblem(); 
        if(ledgern == null){ 
            navigate("/problems", { replace: true });
        }  
        if(ledgern === ""){ 
            navigate("/problems", { replace: true });
        } 
    }, [])
  
    // get AppSid login
    
    const getAppSidlogin = () => { 
        const data = { 
                "AppSid": "",
                "AppVersion": "",
                "Latitude": null,
                "Longitude": null,
                "DeviceToken": "CSSITE",
                "IsKeepLogin": "true",
                "Password": "8D6BB9A4-9CAA-477C-B208-FC5B7E250342",
                "UserName": "CSSITE"
             };  
        // call api for inquery.
        appSidlogin(data)
            .then((result) => {   
                if (result.data.Acknowledge === 1) {
                    setIsDisabled(false);
                    setIsLoading(false);
                    setAppSid(result.data.AppSid);
                    getCustomerInformation(result.data.AppSid); 
                } else {
                    setIsDisabled(false);
                    setIsLoading(false); 
                }
            })
            .catch((err) => {
                setIsDisabled(false); 
                setIsLoading(false);
            }); 
     }
 

    const submitQuery = (e) => {
         var phone = phoneNumber;
            phone = phone.replace(" ", "");
            phone = phone.replace("(", "");
            phone = phone.replace(")", "");
            phone = phone.replace("-", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");
            phone = phone.replace("_", "");

            if (phone == '') {
                setPhoneNumberError("Phone number is required");
                return false;
            }else if (phone.length < 10) {
                setPhoneNumberError("Phone number should be 10 digit number.");
                return false;
             } else {

            setIsLoading(true);
            setPhoneNumberError('');
            setIsDisabled(true);
             const data = {
                "AppNamespace": null,
                "AppSid": "",
                "AppVersion": "",
                "CheckVersion": null,
                "IpAddress": null,
                "Latitude": null,
                "Longitude": null,
                "AppSource": "OL",
                "SaveTransId": null,
                "Phone": '+1' + phone
            };

            // call api for inquery.
            sendCallBackQueue(data)
                .then((result) => {
                    setPhoneNumber("");
                    if (result.status === 200) {
                        setIsDisabled(false);
                        setIsLoading(false);
                        // toast.success('Problem have been added successfully.');
                        navigate("/thankyou-callback");
                    } else {
                        setIsDisabled(false);
                        setIsLoading(false);
                        toast.error("Something went wrong.");
                    }
                })
                .catch((err) => {
                    setIsDisabled(false);
                    toast.error("Something went wrong.");
                    setIsLoading(false);
                });
       }
    };
  
     // get customer information 
     const getCustomerInformation = (appSids) => { 
        setIsLoading(true);
        const data = {   
                "Phone": ledgern,
                "AppSid": appSids 
             };  
        // call api for inquery.
        getCustomerInfo(data)
            .then((result) => { 
                if (result.data.Acknowledge === 1) {
                    setCheckLedgerNum(false);
                    setIsDisabled(false);
                    setIsLoading(false);
                    setCustomerInfo(result.data.resData.Accounts) 
                } else {
                    setCheckLedgerNum(true);
                    setIsDisabled(false);
                    setIsLoading(false);
                    navigate("/problems");
                    toast.error("Details not found,Please enter correct ledger number.");
                }
            })
            .catch((err) => {
                setCheckLedgerNum(true);
                navigate("/problems");
                toast.error("Details not found,Please enter correct ledger number.");
                setIsDisabled(false);
                // toast.error("Something went wrong.");
                setIsLoading(false);
            }); 
     }

     // service call api 
     const submitServiceQuery = () => { 
        setSchDateError("");
        if(startDate === null){
            setSchDateError("Please select schedule date.");
            return false
        }if( startDate === ""){
            setSchDateError("Please select schedule date.");
            return false
        }
        const SchDate = moment(startDate).format("YYYYMMDD");

        // call api for inquery.
        setIsLoading(true);
         const data = { 
            "AppNamespace": null,
            "AppSid": appSid,
            "AppVersion": "",
            "CheckVersion": null,
            "IpAddress": null,
            "Latitude": null,
            "Longitude": null,
            "Action": "A",
            "CallB4": "",
            "CallB4T": "",
            "LgrNo": ledgern,
            "NOC": "BWO",
            "SchDate": SchDate,
            "TimeCode": "",
            "TktNo": ""
         }; 
        // call api for inquery.
        serviceCallBack(data)
            .then((result) => {  
                if (result.status === 200) {
                    setIsDisabled(false);
                    setIsLoading(false);
                    setStartDate("")
                    //navigate("/thankyou-entry");
                    if(result.data.Acknowledge ===1){ 
                        navigate("/thankyou-entry");
                        //toast.success(result.data.Message);
                    }else{ 
                        toast.error(result.data.FullMessage);
                    }
                    
                } else {
                    setIsDisabled(false);
                    setIsLoading(false);
                    toast.error("Something went wrong.");
                }
            })
            .catch((err) => {
                setIsDisabled(false);
                toast.error("Something went wrong.");
                setIsLoading(false);
            });

        // console.log('submitQuery');
    };

    const findProblem = () => {
        setIsLoading(true);
        findProblemName(pid)
            .then((result) => {
                if (result.status === 200) {
                    setIsLoading(false);
                    setProblemsName(result.data);
                    
                } else {
                    navigate("/problems", { replace: true });
                }
            }).catch((err) => { 
                navigate("/problems", { replace: true });
            });
    }


    const getScheduleData = () => {
        setIsLoading(true);
        getScheduleDate()
          .then((result) => {
            if (result.status === 200) {
              setIsLoading(false);
              setShowScheduleDate(result.data); 
            } else {
              setIsLoading(false);
            }
          }).catch((err) => {
            setIsLoading(false);
          });
      }

      
  const holidayDate = [];
  showScheduleDate.forEach((hodate, index) => { 
    holidayDate.push(moment(new Date(hodate.schedule_dates)).format('MMMM-DD-YYYY'));
  });  

  console.log(holidayDate);
    return (
        <>
            {isLoading ?
                <div className='loading'>
                    <div className="loading_wrap">
                        <div className="loading_inner">
                            <p>Loading....</p>
                            <div className="loading_spinner"></div>
                        </div>
                    </div>
                </div>
                : ""
            }
            <div className="steps_wrap">
                <div className="container">
                    <div className="service_entry_block">
                        <div className="service_entry_inner">
                            <div className="summary_blk">
                                <div className="step_title d-flex justify-content-between">
                                    <p className="summary_title">{ problemsName.subTitle }</p>
                                    <p className="ledger_no">Ledger# { ledgern }</p>
                                </div>
                                <div className="customer_info">
                                    <p className="cust_name">{ customerInfo.NAME }</p>
                                    <p className="cust_address">{ customerInfo.ADDRESS1 } <br />{ customerInfo.STATE } { customerInfo.ZIP5 }</p>
                                </div>
                                <div className="cust_problem">
                                    <p><span>Problem</span>: {problemsName.title}</p> 
                                </div> 

                                <div className="cust_problem">
                                    <p>{ problemsName.summary }</p>
                                </div>
                            </div>
                            <div className="button_block d-flex">
                                {
                                    problemsName.callBackButton == 3 ?  
                                <div>
                                <button type="button" className="btn btn-primary red_btn service_call_btn" data-toggle="modal" data-target="#serviceCallModal" variant="primary" onClick={handleServicePopupShow} disabled={ checkLedgerNum } >{problemsName.serviceButtonText}</button> 

                                <button type="button" className="btn btn-primary white_btn call_back_btn" variant="primary" disabled={ checkLedgerNum } onClick={handleShow}>{problemsName.callBackQueueButtonText}</button>
                                </div>
                                : problemsName.callBackButton == 1 ?  <button type="button" className="btn btn-primary red_btn service_call_btn" data-toggle="modal" data-target="#serviceCallModal" variant="primary" onClick={handleServicePopupShow} disabled={ checkLedgerNum }>{problemsName.serviceButtonText}</button>  :  <button type="button" className="btn btn-primary white_btn call_back_btn" variant="primary" onClick={handleShow} disabled={ checkLedgerNum }>{problemsName.callBackQueueButtonText}</button> 
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showServicePopup} onHide={handleServicePopupClose} className="serviceEnterpopup">
                <Modal.Header closeButton variant="white">
                </Modal.Header>
                <Modal.Body>
                    <div className="popup_msg_wrap d-flex ">
                        <div className="icon"><span className="fa fa-question" aria-hidden="true"></span></div>
                        <div className='datePickerMain'>
                        <div className="msg"><p>{problemsName.popUpText}</p></div> 

                        <div className='datePicker'>    
                        <label>Schedule Date</label>     
                        <DatePicker 
                            format='DD-MM-YYYY' 
                            selected={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            minDate={new Date()} 
                              mapDays={({ date }) => {
                                let isWeekend = [0, 6].includes(date.weekDay.index)
                                let disabledDate = holidayDate.includes(date.month.name+"-"+date.day+'-'+date.year) 
                                if (isWeekend) return {
                                  disabled: true,
                                  style: { color: "#ccc" },
                                  onClick: () => alert("Weekends")
                                }
                                if (disabledDate === true) return {
                                  disabled: true,
                                  style: { color: "#ffff00" },
                                  onClick: () => alert("Holiday")
                                }
                              }}
                              placeholder ="Select schedule date" 

                            placeholderText="Select Schedule Date"                            
                        /> 
                        </div>
                        {schDateError && (<p className="text-danger text-gradient small mb-2">{schDateError}</p>)}
                        
                        </div>
                    </div>
                    {/* <LoginForm onSubmit={onLoginFormSubmit} /> */}
                </Modal.Body>
                <div className="modal-footer popup_btns">
                    
                    <button type="button" className="btn btn-primary popup_yes_btn" variant="secondary" onClick={submitServiceQuery}>Yes</button>
                    
                    <button type="button" className="btn btn-primary popup_no_btn" variant="secondary" onClick={handleServicePopupClose}>No</button>
                </div>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton variant="white"></Modal.Header>
                <Modal.Body>
                    <div className="popup_msg_wrap d-flex">
                        <div className="icon"><span className="fa fa-phone" aria-hidden="true"></span></div>
                        <div className="msg">
                            <p>Enter Your Phone Number</p>
                            <form>
                                <div className="form-group">
                                    <InputMask
                                        mask='(999) 999-9999'
                                        value={phoneNumber}
                                        placeholder='Phone Number'
                                        className="form-control"
                                        onChange={handleInput}>
                                    </InputMask>
                                    {/* <div style={{paddingTop: '12px'}}>Phone: {phone}</div> */}
                                    {phoneNumberError && (<p className="text-danger text-gradient small mb-2">{phoneNumberError}</p>)}
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <div className="modal-footer popup_btns">
                    {/* <a href="thankyou-callback.html" className="btn btn-primary popup_yes_btn">Yes</a> */}
                    <button type="button" className="btn btn-primary popup_yes_btn" variant="secondary" onClick={submitQuery} disabled={isDisabled}>Yes</button>
                    <button type="button" className="btn btn-primary popup_no_btn" variant="secondary" onClick={handleClose} >No</button>

                </div>
            </Modal>

        </>
    )
}

export default ServiceEntry

 import { constant } from './baseUrl';
const axios = require('axios'); 
export const getToken = () => { 
    const data = JSON.parse(localStorage.getItem('userDetails')); 
    return (data !== null) ? data.accessToken : null;  
}

 export const findProblemName = async (param) => {  
    try {  
         const response = await axios.get(constant.BASE_PATH +  "findProblemName/?id="+param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 }

 export const findProblemByCate = async (param) => { 
    try {  
         const response = await axios.get(constant.BASE_PATH +  "findBySlugProblem/?slug="+param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 }
 
 export const countProblemQuestions = async (param) => { 
    try {  
         const response = await axios.get(constant.BASE_PATH +  "countProblemQuestions/?id="+param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 } 

 export const fetchProblemQuestionsData = async (param) => { 
    try {  
         const response = await axios.post(constant.BASE_PATH +  "findByProblemQuestionsList",param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 }
 
 export const sendCallBackQueue = async (param) => { 
    try {  
         const response = await axios.post(constant.CALLBACK_QUEUE_URL,param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 }

 export const serviceCallBack = async (param) => { 
    try {  
         const response = await axios.post(constant.SERVICE_CALBACK_URL,param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 }

 export const getCustomerInfo = async (param) => { 
    try {  
         const response = await axios.post(constant.GETCUSTOMERINFORMATION,param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 }

 export const appSidlogin = async (param) => { 
    try {  
         const response = await axios.post(constant.GETAPPSIDLOGIN,param,{ headers: { "Content-Type": "application/json", "Accept": "application/json" } });  
         return response;
     } catch (err) {
         console.log(err);
     }
 }

 export const getScheduleDate = async () => { 
    try { 
        const response = await axios.get(constant.BASE_PATH + "getScheduledate", { headers: { "Content-Type": "application/json","Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}
 
import React, { useState, useEffect,useMemo} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom'
import { getQuestionsList, removeQuestionItem,getProblemsView,updateQuestionPostion } from '../../services/Auth';  
import { getToken } from '../../services/Auth'; 
import QuestionPositionComponets from '../Problems/QuestionPositionComponets';
import { toast } from "react-toastify"; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment'; 
import { TableHeader, Pagination, Search } from "../DataTable";
import {useSelector} from 'react-redux';
const SingleProblem = () => {
  const token = getToken();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); 
  const [title, setTitle] = useState(""); 
  const paramId = useParams();  
  const userPermission = useSelector(state => state.user); 
  useEffect(() => {  
      if(userPermission.user && userPermission.user.view_records == 0){
        navigate("/admin/problems-list/"+paramId.catId);
      }  
  }, [userPermission]);
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState("");
  const ITEMS_PER_PAGE = 15;

  const headers = [
    { name: "Sr.No.", field: "id", sortable: false },
    { name: "Questions Title", field: "title", sortable: true },
    { name: "Created At", field: "created_at", sortable: true },
    { name: "Actions", field: "actions", sortable: false }
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        comment =>
          comment.questions_title.toLowerCase().includes(search.toLowerCase())
      );
    } 
    setTotalItems(computedComments.length); 
    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    } 
    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting]);


  useEffect(() => {
    getQuestionsListData();
    getSingleTitle(); 
  }, [])

  const submitPostion = (position,quesIds) => {  
    setIsLoading(true);
      const data = {
        proIds : quesIds,
        OrderPosition: position,
      };
      updateQuestionPostion(data,token)
        .then((result) => {
          setIsDisabled(false);
          setIsLoading(false); 
          if (result.status === 200) {
            getQuestionsListData();
            toast.success('Position updated successfully.'); 
          } else {
            setIsLoading(false);
            toast.error("position couldn't added Please try again."); 
          }
        })
        .catch((err) => {
          setError("Error in update position");
          setIsLoading(false);
        });
    }

  const getSingleTitle = () => {  
    getProblemsView(paramId,token)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setTitle(result.data.title);
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
      });
  }
 
  const getQuestionsListData = () => { 
    setIsLoading(true);
    getQuestionsList(paramId,token)
      .then((result) => { 
        if (result.status === 200) {
          setIsLoading(false);
          // setGetQuestion(result.data);
          setComments(result.data);
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
      });
  }
 
  const handleClickDeleteItem = (paramId) => {
    confirmAlert({
      // title: 'Confirm Alert!.',
      message: 'Are you sure you want to delete this question?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsLoading(true);
            removeQuestionItem(paramId, token)
              .then((result) => { 
                setIsLoading(false);
                if (result.data.status === 1) {
                  getQuestionsListData();
                  toast.success(result.data.message);
                } else {
                  toast.error("Question couldn't deleted Please try again.");
                }
              }).catch((err) => {
                toast.error("Something wrong");
              });
          }

        },
        {
          label: 'No', 
        }
      ]
    });
  }

  return (
    <>
      {isLoading ?
              <div className='loading'>
                <div className="loading_wrap">
                <div className="loading_inner">
                  <p>Loading....</p>
                  <div className="loading_spinner"></div>
                </div>
              </div> 
            </div> 
            : ""
        }   
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
          <Link to={{ pathname: `/admin/dashboard`}}>Dashboard</Link></li> 
            <li className="breadcrumb-item">
             <Link to={{ pathname: `/admin/problems-list/${paramId.catId}`}}>Problems</Link></li>
            <li className="breadcrumb-item"><Link className="breadcrumb-item" to={{ pathname: `/admin/problems-list/${paramId.catId}`}}>{
                paramId.catId === '1' ? 'Heating Services' : paramId.catId === '2' ? 'Security Services' :'Cooling Services'
            }</Link></li>  
            <li className="breadcrumb-item"><Link className="breadcrumb-item active" to={{ pathname: `/admin/problems-list/${paramId.catId}`}}>{title}</Link></li>  
          </ol>
        </nav>
        <div className="all_prob_wrap">
          <div className="all_prob_inner">
            <div className="table_title d-flex">
              <h2>{title}</h2> 
              <Link className="btn btn-primary btn_add_prob" to={{pathname: `/admin/add-questions/${paramId.catId}/${paramId.id}`}}>Add New Question</Link> 
            </div>
            <div className='table-warp-search-pagination'>
          <div className='d-flex custom-pagination justify-content-between'> 
            
          <Search
            onSearch={value => {
              setSearch(value);
              setCurrentPage(1);
            }}
          />
          <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={page => setCurrentPage(page)}
            />
          </div>

    <table className="cell-border table table-hover table-bordered table-striped postionInput" style={{ width: '100%' }} >
            <TableHeader
              headers={headers}
              onSorting={(field, order) =>
                setSorting({ field, order })
              }
            />
             <tbody>
              { commentsData.length > 0 ? commentsData.map((proItem, podidx) => (
                <tr key={podidx} >
                  <td>{ (currentPage - 1) * ITEMS_PER_PAGE + podidx + 1 }</td>
                  <td>{proItem.questions_title}</td>
                  <td>
                        <QuestionPositionComponets submitPostion={ submitPostion } proIds={proItem._id} position={proItem.OrderPosition} />
                        </td>
                  <td>{moment(proItem.created_at).format("MM-DD-YYYY")}</td>
                  <td>
                      <Link className="btn btn-primary btn_red_extsm edit_btn" 
                        to={{
                          pathname: `/admin/edit-questions/${paramId.catId}/${proItem._id}/${paramId.id}` 
                        }} title="Edit"><i className="fa fa-edit"></i></Link>   
                      <button onClick={() => handleClickDeleteItem( proItem._id ) } title="Delete" className="btn btn-primary btn_red_extsm delete_btn"><i className="fa fa-trash"></i></button> 
                      </td> 
                  </tr>
              ))
              : <tr><td style={{ textAlign: 'center' }}  colSpan="6">No record found.</td></tr>
            }
            </tbody>
          </table>
 </div>
          </div>
        </div>  
    </>
  )
}

export default SingleProblem;
import React, { useState, useEffect } from 'react'  
import { useAuth } from "../../layouts/useAuth"; 
import LogoImage from '../../assets/img/Slomins-Logo.svg';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';

const Header = () => { 

    const { logout } = useAuth();
    
    useEffect(() => {
        $(document).ready(function () {
            $(".js-hamburger").on("click", function () {
                $(".sidebar-dropdown").removeClass("active");
                $("body").toggleClass("sidebar-is-reduced sidebar-is-expanded");
                $(".hamburger-toggle").toggleClass("is-opened");
            }); 
            $(".sidebar-dropdown > a").click(function () {
                $(".sidebar-submenu").slideUp(200);
                if ($(this).parent().hasClass("active")) {
                    $(".sidebar-dropdown").removeClass("active");
                    $(this).parent().removeClass("active");
                } else {
                    $(".sidebar-dropdown").removeClass("active");
                    $(this).next(".sidebar-submenu").slideDown(200);
                    $(this).parent().addClass("active");
                }
            });
        });
    }, []) 
    return (
        <>
            <ToastContainer /> 
            <header className="header">
                <div className="header_inner clearfix">
                    <div className="logo">
                        <div className="logo_img">
                            <img src={LogoImage} alt="Slomins" />
                        </div>
                    </div>
                    <div className="header-icon js-hamburger">
                        <div className="hamburger-toggle"><span className="bar-top"></span><span className="bar-mid"></span><span className="bar-bot"></span></div>
                    </div>
                    <div className="header-icons-group">
                        <div className="header-icon logout_admin"><i className="fa fa-user"></i><span>Admin</span></div>
                    </div>
                </div>
            </header> 
        </>
    )
}

export default Header

import React from 'react'
import LogoImg from '../../assets/img/Slomins-Logo.svg'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { constant } from '../../services/baseUrl';
const OuterHeader = () => {
  return (
    <>
      <ToastContainer />
      <header className="header">
        <div className="container">
          <div className="header_inner row">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
              <div className="header_logo_wrap"><a href={constant.STATIC_PAGE_URL} target="_blank"><img className="img-fluid" src={LogoImg} alt="slomins logo" /></a></div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
              <div className="header_additional_info_wrap text-right">
                <ul>
                  <li><a href="tel:18667566467"><i className="fa fa-phone" aria-hidden="true"></i><span>1-866-756-6467</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default OuterHeader

import React, { useState, useEffect } from 'react'
import { routes } from '../../constants/routes'
import { Link, useLocation } from 'react-router-dom' 
import { useSelector } from 'react-redux'; 
import { constant } from '../../services/baseUrl';
import Cookies from 'js-cookie';
const Sidebar = () => {
    const userPermission = useSelector(state => state.user);  
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    const UrlCheck = splitLocation[2] + '/' + splitLocation[3];
    const [showStore, setShowStore] = useState(""); 
    const handleLogout = () => {
        Cookies.remove(constant.Cookie_Name, { domain: constant.Cookie_Domain });
        window.location.href =  constant.AUTH_ADMIN_URL + '/login?ref=' + btoa(window.location.href)
    } 
    useEffect(() => {
        if (splitLocation[2] != 'dashboard' && splitLocation[2] != "schedule-date" && splitLocation[2] != "add-scheduledate") {
            setShowStore(true);
        } else {
            setShowStore(false);
        }
    }, [pathname])

    return (
        <>
            <div className="sidebar">
                <div className="sidebar-wrapper">
                    <div className="sidebar-menu">
                        <ul>
                            <li className={`dashboard ${splitLocation[2] === "" || splitLocation[2] === "dashboard" ? "active" : ""} `}>
                                <Link to={routes.DASHBOARD}>
                                    <span className="dashboard_icon sidebar_icon"></span>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li className={`sidebar-dropdown problem ${splitLocation[2] === "add-problems" || splitLocation[2] === "problems-list" || splitLocation[2] === "single-problems-list" || splitLocation[2] === "add-questions" || splitLocation[2] === "edit-questions" ? "active" : ""} `}>
                                <a href="#">
                                    <span className="problem_icon sidebar_icon"></span>
                                    <span>Problems</span>
                                </a>

                                <div className="sidebar-submenu" style={{ display: showStore == "" ? 'none' : 'block' }}
                                >
                                    <ul>
                                        <li>
                                            <Link to={{ pathname: '/admin/problems-list/1' }} className={`${pathname == '/admin/problems-list/1' || UrlCheck == 'admin/add-problems/1' || UrlCheck == 'admin/edit-problems/1' || UrlCheck == 'admin/single-problems-list/1' || UrlCheck == 'admin/add-questions/1' || UrlCheck == 'admin/edit-questions/1' ? 'active' : ""}`}>Heating  Services</Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: '/admin/problems-list/2' }} className={`${pathname == '/admin/problems-list/2' || UrlCheck == 'admin/single-problems-list/2' || UrlCheck == 'admin/add-problems/2' || UrlCheck == 'admin/edit-problems/2' || UrlCheck == 'admin/add-questions/2' || UrlCheck == 'admin/edit-questions/2' ? 'active' : ""}`}>Security Services</Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: '/admin/problems-list/3' }} className={`${pathname == '/admin/problems-list/3' || UrlCheck == 'admin/single-problems-list/3' || UrlCheck == 'admin/add-problems/3' || UrlCheck == 'admin/edit-problems/3' || UrlCheck == 'admin/add-questions/3' || UrlCheck == 'admin/edit-questions/3' ? 'active' : ""}`}>Cooling Services</Link>
                                        </li >
                                    </ul>
                                </div>
                            </li>
                            {
                                userPermission.user && userPermission.user.view_schedule_date == 1 ?
                                    <li className={`schedule-date ${splitLocation[2] === "schedule-date" || splitLocation[2] === 'add-scheduledate' ? "active" : ""} `}>
                                        <Link to={{ pathname: '/admin/schedule-date' }} >
                                            <span className="schduledate_icon"></span>
                                            <span className="">Schedule Date</span>
                                        </Link>
                                    </li>
                                    : ""
                            } 
                            <li className="logout" onClick={handleLogout}>
                                <a>
                                    <span className="logout_icon sidebar_icon"></span>
                                    <span className="logout_span">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar
import React,{useEffect} from 'react' 
import { useAuth } from "./useAuth";
import Sidebar from '../components/Includes/Sidebar'
import Header from '../components/Includes/Header'  
import addData from '../services/actions/actions';
import { useDispatch } from 'react-redux';
import { getProfile } from '../services/Auth';
import { constant } from '../services/baseUrl'; 
import '../assets/css/self_service_admin.css';
function RequireAuth({ children }) { 
  const { authed } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    if(authed === true ){
        fetchProfile();
      }
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await getProfile();
      if (response.User) {
        const permission = response.User;
        const userPermissionModule = {
          copy_link: permission.permissionNames && permission.permissionNames.includes('copy link') ? 1 : false,
          send_sms: permission.permissionNames && permission.permissionNames.includes('send sms') ? 1 : false,
          add_records: permission.permissionNames && permission.permissionNames.includes('add records') ? 1 : false,
          edit_records: permission.permissionNames && permission.permissionNames.includes('edit records') ? 1 : false,
          view_records: permission.permissionNames && permission.permissionNames.includes('view records') ? 1 : false,
          delete_records: permission.permissionNames && permission.permissionNames.includes('delete records') ? 1 : false,
          view_schedule_date: permission.permissionNames && permission.permissionNames.includes('view schedule date') ? 1 : false,
          add_schedule_date: permission.permissionNames && permission.permissionNames.includes('add schedule date') ? 1 : false,
          delete_schedule_date: permission.permissionNames && permission.permissionNames.includes('delete schedule date') ? 1 : false
        }    
        dispatch(addData(userPermissionModule));
      } else {
          window.location.href = constant.AUTH_ADMIN_URL;
      }
    } catch (error) {
      console.log(error); 
    }
  };  
  return authed === true ? (
    <>   
     <Header /> 
        <main className="main">
           <Sidebar />
           <div className="content-wrapper">
             {children}
         </div>
      </main> 
    </>
  ) : (
    window.location.href = constant.AUTH_ADMIN_URL + '/login?ref=' + btoa(window.location.href)
  );
}
export default RequireAuth;
export const routes = {
    DEFAULT: "/",
    LOGIN:"/admin/login",
    DASHBOARD:'/admin/dashboard',
    PROBLEMLIST : '/admin/problems-list',  
    ADDPROBLEM : '/admin/add-problems',  
    EDITPROBLEM : '/admin/edit-problems', 
    SINGLEPROBLEMSLIST : '/admin/single-problems-list',  
    ADDQUESTIONS : '/admin/add-questions',  
    CHANGEPASSWORD:'/admin/change-password',
    FORGOTPASSWORD:'/admin/forgot-password', 
};
import React, { useState, useEffect, useMemo } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { removeScheduleDate,getScheduleDate } from '../../services/Auth';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { TableHeader, Pagination, Search } from "../DataTable";
import {useSelector} from 'react-redux';
const ScheduleDate = () => {
  const userPermission = useSelector(state => state.user);
  const paramId = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation(); 
  const [showScheduleDate, setShowScheduleDate] = useState([]); //
   
  useEffect(() => {
    setSearch("");
    getScheduleData();
  }, []) 
 
  const getScheduleData = () => { 
    setIsLoading(true);
    getScheduleDate()
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setShowScheduleDate(result.data); 
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
      });
  } 
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const ITEMS_PER_PAGE = 15;
  const headers = [
    { name: "Schedule Date", field: "schedule_dates", sortable: true },
    { name: "Actions", field: "actions", sortable: false }
  ];
  const commentsData = useMemo(() => {  
    let computedComments = showScheduleDate;
    if (search) { 
      computedComments = computedComments.filter(
        comment =>
          comment.schedule_dates.includes(search)
      );
    }
    setTotalItems(computedComments.length);
    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [showScheduleDate, currentPage, search, sorting]);
 
  
  const handleClickDeleteItem = (paramId) => {
    confirmAlert({
      message: 'Are you sure you want to delete this schedule date?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsLoading(true);
            removeScheduleDate(paramId)
              .then((result) => {
                setIsLoading(false);
                if (result.data.status === 1) {
                  getScheduleData();
                  toast.success(result.data.message);
                } else {
                  toast.error("schedule data couldn't deleted Please try again.");
                }
              }).catch((err) => {
                toast.error("Something wrong");
              });
          }

        },
        {
          label: 'No',
        }
      ]
    });
  }

  return (
    <>
      {isLoading ?
        <div className='loading'>
          <div className="loading_wrap">
            <div className="loading_inner">
              <p>Loading....</p>
              <div className="loading_spinner"></div>
            </div>
          </div>
        </div>
        : ""
      }

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={{ pathname: `/admin/dashboard` }}>Dashboard</Link></li>
          <li className="breadcrumb-item active">Schedule Date</li>
        </ol>
      </nav> 
      <div className="all_prob_wrap">
        <div className="all_prob_inner">
        {
            userPermission.user && userPermission.user.add_schedule_date == 1 ?
          <div className="table_title d-flex">
          <Link className="btn btn-primary btn_add_prob" to={{ pathname: `/admin/add-scheduledate/` }}>Add Schedule Date
            </Link>
          </div>
          :""
        }

          <div className='table-warp-search-pagination'>
            <div className='d-flex custom-pagination justify-content-between'>
             <Search
                onSearch={value => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              /> 
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={page => setCurrentPage(page)}
              />
            </div>
            <table className="cell-border table table-hover table-bordered schduleDateTable" style={{ width: '100%' }} >
              <TableHeader
                headers={headers}
                onSorting={(field, order) =>
                  setSorting({ field, order })
                }
              />
              <tbody>
                   { commentsData.length > 0 ? commentsData.map((proItem, podidx) => ( 
                <tr key={podidx}>
                  <td><span className="badge badge-info">{proItem.schedule_dates}</span></td>
                  
                  <td>
                  {
                    userPermission.user && userPermission.user.delete_schedule_date == 1 ?
                    <button onClick={() => handleClickDeleteItem(proItem._id)} title="Delete" className="btn btn-primary btn_red_extsm delete_btn"><i className="fa fa-trash"></i></button>
                    :""
                    }
                  </td>
                </tr>
                   ))
                   : <tr><td style={{ textAlign: 'center' }}  colSpan="2">No record found.</td></tr>
                 }
              </tbody>
            </table>
          </div>
        </div>
      </div>


    </>
  )
}

export default ScheduleDate;
import { constant } from './baseUrl';
import Cookies from 'js-cookie';
const axios = require('axios'); 
const BASE_PATH =  constant.BASE_PATH;
const Allow_Origin =  constant.Allow_Origin;
const PERMISSION_API =  constant.PERMISSION_LIST_API; 
export const getToken = () => {  
    return Cookies.get(constant.Cookie_Name);  
    //  const data = JSON.parse(localStorage.getItem('userDetails')); 
    //  return (data !== null) ? data.accessToken : null;  
}

export const userLogin = async (param) => {
    try {     
        const response = await axios.post(BASE_PATH + "login", param, { headers: { "Content-Type": "application/json" ,"Access-Control-Allow-Origin": Allow_Origin } }); 
         return response;
    } catch (err) {
        console.log(err);
    }
} 

export const addProblems = async (param,token) => {
    try { 
        const response = await axios.post(BASE_PATH + "problems", param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const updatePostion = async (param,token) => {
    try { 
        const response = await axios.put(BASE_PATH + "updatePostion/"+param.proIds,param,{ headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}

// questions
export const updateQuestionPostion = async (param,token) => {
    try { 
        const response = await axios.put(BASE_PATH + "updateQuestionsPostion/"+param.proIds,param,{ headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const getProblemsList = async (param,token) => { 
    try { 
         const response = await axios.get(BASE_PATH +  "problems/?catId="+param,{ headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } }); 
         return response;
     } catch (err) {
         console.log(err);
     }
 } 


 export const getProblemsView = async (param,token) => { 
    try {      
         const response = await axios.get(BASE_PATH + "problems?id="+param.id, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
         return response; 
     } catch (err) {
         console.log(err);
     }
 }

 export const UpdateProblem = async (param,token) => { 
    try {      
         const response = await axios.put(BASE_PATH + "problems/"+param.id,param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
         return response; 
     } catch (err) {
         console.log(err);
     }
}

    export const removeProItem = async (param,token) => { 
        try {       
             const response = await axios.delete(BASE_PATH + "problems/"+param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
             return response; 
         } catch (err) {
             console.log(err);
         }
 } 

 export const getQuestionsList = async (param,token) => { 
    try { 
         const response = await axios.get(BASE_PATH + "questions/?problem_id="+param.id, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
        return response;  
     } catch (err) {
         console.log(err);
     }
 } 

 export const addQuestions = async (param,token) => {
    try { 
        const response = await axios.post(BASE_PATH + "questions", param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}
 
export const UpdateQuestion = async (param,token) => { 
    try {      
         const response = await axios.put(BASE_PATH + "questions/"+param.id,param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
         return response; 
     } catch (err) {
         console.log(err);
     }
}

export const getQuestionView = async (param,token) => { 
    try {      
         const response = await axios.get(BASE_PATH + "questions?id="+param.id, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
         return response; 
     } catch (err) {
         console.log(err);
     }
 }

 export const removeQuestionItem = async (param,token) => { 
    try {       
         const response = await axios.delete(BASE_PATH + "questions/"+param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
         return response; 
     } catch (err) {
         console.log(err);
     }
} 
   
 
export const updatePassword = async (param, token) => {
    try {
        const response = await axios.post(BASE_PATH + "user/changepassword", param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const userForgotPassword = async (param , token) => {

    try {
       const response = await axios.post(BASE_PATH + "user/forgetpassword", param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });  
        return response;
    } catch (err) { 
        console.log(err);
    }
}  

 export const getEmailIdByToken = async (param) => {

    try {
        const response = await axios.post(BASE_PATH + "user/get-email-bytoken", param, { headers: { "Content-Type": "application/json" } });
        return response;
    } catch (err) { 
        console.log(err);
    }
} 

 export const resetPassword = async (param , token) => { 
    try {
        const response = await axios.post(BASE_PATH + "user/resetPassword", param, { headers: { "Content-Type": "application/json" }}); 
        return response;
    } catch (err) { 
        console.log(err);
    }
} 

 export const updateVerifyAccount = async (param) => { 
    try {
        const response = await axios.post(BASE_PATH + "user/verify-account", param, { headers: { "Content-Type": "application/json" }}); 
        return response;
    } catch (err) { 
        console.log(err);
    }
} 

export const saveScheduleDate = async (param,token) => { 
    try { 
        const response = await axios.post(BASE_PATH + "addScheduleDate", param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const getScheduleDate = async () => { 
    try { 
        const response = await axios.get(BASE_PATH + "getScheduledate", { headers: { "Content-Type": "application/json","Accept": "application/json" } });
        return response;
    } catch (err) {
        console.log(err);
    }
}  

export const removeScheduleDate = async (param) => { 
    try {       
         const response = await axios.delete(BASE_PATH + "scheduleDate/"+param, { headers: { "Content-Type": "application/json","Accept": "application/json" } });
         return response; 
     } catch (err) {
         console.log(err);
     }
}

export const sendLinkToCustomer = async (param,token) => { 
    try {  
        const response = await axios.post(BASE_PATH + "sendSMSCustomer", param, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token, "Accept": "application/json" } });  
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const getCsPermissionModule = async (param) => { 
    try {  
        const response = await axios.get(PERMISSION_API+"?uid="+param,{ headers: { "Content-Type": "application/json","Accept": "application/json"} }); 
        return response;
    } catch (err) {
        console.log(err);
    }
}

export const getProfile = async () => {
    let token = Cookies.get(constant.Cookie_Name);
    const response = await axios.get(`${constant.API_BASEPATH}/get-user?site_id=${constant.SITE_ID}`, { headers: { "Authorization": `Bearer ${token}` } }).then(function (res) {
      const resp = res.data;
      if (resp.Acknowledge == 1) {
        return resp;
      } else {
        return false;
      }
    }).catch(err => {
      return false;
    });
    return response;
  };



















 



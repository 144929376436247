import React, { useState, createContext, useContext } from 'react'
import Cookies from 'js-cookie';
import { constant } from '../services/baseUrl';
const authContext = createContext();
export function useAuth() {
  let token = Cookies.get(constant.Cookie_Name);
  let flag = false;
  if (token) {
    if (token) {
      flag = true;
    }
  }
  const [authed, setAuthed] = useState(flag);
  return {
    authed,
    login() {
      return new Promise((res) => {
        setAuthed(true);
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        res();
      });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
export default function AuthConsumer() {
  return useContext(authContext);
}

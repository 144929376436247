import React,{useState} from "react";
import playPtore from '../../assets/img/play_store.png';
import appStore from '../../assets/img/app_store.png'; 
import { constant } from '../../services/baseUrl';
const OuterFooter = () => {
  const todayDate = new Date();
  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());
  return (
    <>
     <div className="video_popup_wrap" id="video_tutorial">
        <div className="video_popup_inner">
          <div className="popup_close_btn"><i className="fa fa-close" aria-hidden="true"></i></div>
          <div className="video_wrap youtube"></div>
        </div>
      </div> 
      <div className="popup_overlay"></div>


      <footer className="footer front-footer">
        <div className="footer_widgets_wrap">
          <div className="container">
            <div className="row">
              <div className="widgets_content col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h4>About</h4>
                <ul className="down_panel">
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'locations'} target="_blank">Locations</a>
                  </li>
                  <li>
                    <a href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?ccId=1728567304_3745&amp;cid=9f690535-b097-49a1-ac9d-0e3071b27ea3" target="_blank">Careers</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'customer-reviews'} target="_blank">Reviews</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'referral-program'} target="_blank">Referral Program</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'blog'} target="_blank">Blog</a>
                  </li>
                </ul>
              </div>
              <div className="widgets_content col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h4>Home Security</h4>
                <ul className="down_panel">
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'home-security/home-security-products'} target="_blank">Products</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'home-security/home-automation'} target="_blank">Home Automation</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'home-security/home-security-cameras'} target="_blank">Remote Control</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'home-security/professional-monitoring'} target="_blank">Professional Monitoring</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'home-security/why-choose-the-slomins-shield'} target="_blank">Why Us</a>
                  </li>
                </ul>
              </div>
              <div className="widgets_content col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h4>For Business</h4>
                <ul className="down_panel">
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'for-business/security-system'} target="_blank">Security System</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'for-business/cooling'} target="_blank">Cooling</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'for-business/heating'} target="_blank">Heating</a>
                  </li>
                </ul>
              </div>
              <div className="widgets_content col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h4>Help &amp; Support</h4>
                <ul className="down_panel">
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'faq'} target="_blank">FAQ</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'contact-us'} target="_blank">Contact Us</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'get-a-quote'} target="_blank">Get a Quote</a>
                  </li>
                </ul>
                <p>
                  <span className="store_link_wrap">
                    <a href="https://play.google.com/store/apps/collection/cluster?clp=igNJChkKEzUxNzMwMjcyOTE2NjUyNDQzNzEQCBgDEioKJGNvbS5zZWN1cmVuZXR0ZWNoLnNsb21pbnM2cHJvZHVjdGlvbhABGAMYAQ%3D%3D:S:ANO1ljJf4Iw&amp;gsr=CkyKA0kKGQoTNTE3MzAyNzI5MTY2NTI0NDM3MRAIGAMSKgokY29tLnNlY3VyZW5ldHRlY2guc2xvbWluczZwcm9kdWN0aW9uEAEYAxgB:S:ANO1ljLGHzU&amp;hl=en_US&amp;gl=US" target="_blank">
                      <img src={playPtore} alt="Google Play" />
                    </a>
                    <a href="https://apps.apple.com/us/developer/slomins-inc/id468595151" target="_blank">
                      <img src={appStore} alt="App Store" />
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_copyright_wrap">
          <div className="copyright_inner container">
            <div className="copyright_content row">
              <div className="copyright col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <p>© {currentYear} Slomin's® Inc. All Rights Reserved.</p>
              </div>
              <div className="social_media col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <ul>
                  <li>
                    <a href="https://www.youtube.com/c/Slominsshield" target="_blank">
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/slomins.inc/" target="_blank">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/slomins" target="_blank">
                      <i className="fa fa-facebook" aria-hidden="true "></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Slomins" target="_blank">
                      <i className="fa fa-twitter" aria-hidden="true "></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="copyright_menu col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <ul>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'term-of-use'} target="_blank">Terms of Use</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'privacy-policy'} target="_blank">Privacy Policy</a>
                  </li>
                  <li>
                    <a href={constant.STATIC_PAGE_URL+'license'} target="_blank">Licenses</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer> 
    </>
  );
};

export default OuterFooter;

import React, { useState, useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { saveScheduleDate, getScheduleDate } from '../../services/Auth';
import { toast } from "react-toastify";

import moment from 'moment';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import {useSelector} from 'react-redux';
const AddScheduleDate = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState("");
  const [scheduleDateValue, setScheduleDateValue] = useState("");

  const userPermission = useSelector(state => state.user); 
  useEffect(() => {  
    if(userPermission.user && userPermission.user.add_schedule_date == 0){
      navigate("/admin/schedule-date");
    }  
}, [userPermission]);

  const navigate = useNavigate();

  useEffect(() => {
    getScheduleData();
  }, [])

  const [storeScheduleDate, setStoreScheduleDate] = useState(""); //
  const [scheduleError, setScheduleError] = useState(""); // 
  const [showScheduleDate, setShowScheduleDate] = useState([]); //

  const handleDateChange = (dateValue) => {
    let dates = dateValue.map((i, d) => {
      return moment(new Date(i)).format('MM-DD-YYYY');
    })
    setStoreScheduleDate(dates);
  }

  const getScheduleData = () => {
    setIsLoading(true);
    getScheduleDate()
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setShowScheduleDate(result.data); 
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
      });
  }

  const submitForm = (e) => {
    e.preventDefault();
    const data = {
      scheduleDate: storeScheduleDate,
    };
    if(storeScheduleDate.length > 0 ){
      setScheduleDateValue("");
      saveScheduleDate(data)
        .then((result) => {
          setIsDisabled(false);
          setIsLoading(false);
          if (result.status === 200) {
            navigate("/admin/schedule-date");
            getScheduleData();
            toast.success('Schedule date added successfully.');
          } else {
            setIsLoading(false);
            toast.error("Schedule date couldn't added Please try again.");
          }
        })
        .catch((err) => {
          toast.error("Error in schedule date");
          setIsLoading(false);
        });
    }else{
      setIsDisabled(false);
      setIsLoading(false); 
      if (storeScheduleDate.length === 0 ) {
        setScheduleError("Please select at least one schedule date.");
      } 
      return false;
    }  

  }; 

  const holidayDate = [];
  showScheduleDate.forEach((hodate, index) => { 
    holidayDate.push(moment(new Date(hodate.schedule_dates)).format('MMMM-DD-YYYY'));
  });  
  return (
    <>
      {isLoading ?
        <div className='loading'>
          <div className="loading_wrap">
            <div className="loading_inner">
              <p>Loading....</p>
              <div className="loading_spinner"></div>
            </div>
          </div>
        </div>
        : ""
      } 
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
          <li className="breadcrumb-item"><Link className="breadcrumb-item" to={{ pathname: `/admin/schedule-date/` }}>Schedule Date</Link></li>
        </ol>
      </nav>
      <div className="add_new_prob_wrap">
        <div className="add_new_prob_inner">
          <h2>Add New Schedule Date</h2>
          <form onSubmit={submitForm}>
            <div className="form-group datePicker">
              <label htmlFor="inputTitle">Schedule Date</label>
              <div className='scheduleDateinput'>
              <DatePicker
                multiple   
                minDate={new Date()}
                format='DD-MM-YYYY' 
                onChange={(scheduleDateValue) => handleDateChange(scheduleDateValue ? scheduleDateValue : "")}
                plugins={[
                  <DatePanel />
                ]} 
                mapDays={({ date }) => {
                  let isWeekend = [0, 6].includes(date.weekDay.index)
                  let disabledDate = holidayDate.includes(date.month.name+"-"+date.day+'-'+date.year) 
                  if (isWeekend) return {
                    disabled: true,
                    style: { color: "#ccc" },
                    onClick: () => alert("Weekends")
                  }
                  if (disabledDate === true) return {
                    disabled: true,
                    style: { color: "#ffff00" },
                    onClick: () => alert("Holiday")
                  }
                }}
                placeholder ="Select schedule date"
              />
              {scheduleError && ( <p className="text-danger text-gradient small mb-2">{scheduleError}</p>)}
              <button type='submit' className="btn btn-primary btn_add_prob btn_add_schedul" disabled={isDisabled}>
                {isDisabled ? "Submitting..." : "Save"}
              </button>
              </div>
            </div>

          </form>
        </div>
      </div>


    </>
  )
}

export default AddScheduleDate;
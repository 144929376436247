import React,{useState} from 'react'; 

const QuestionPositionComponets = (props) => {

  const [position, setPosition] = useState(props.position);

  const handlePositionChange = (e) => { 
    e.preventDefault();
    const val = e.target.value; 
    setPosition(val); 
  };  
  return (
    <>

      <input type="number" min='0' onChange={handlePositionChange } value={position}/> 

      <button type='button' className='btn btn-primary btn_update_postion' onClick={(e) => props.submitPostion(position,props.proIds)}><i className="fa fa-save"></i></button> 
    </>

  )
}

export default QuestionPositionComponets;
import React from 'react'

const Page404Error = () => {
    return (
        <>    
        <div className="error_wrap">
          <div className="container">
            <div className="row">
              <div className="col-12 page_title">
                <div className="errormessage_blk">
                  <h1>404</h1><h3>Oops! Page not found</h3>
                  <p>Sorry, the page you are looking for does not exist.</p>
                  {/* <a className="btn btn-primary error_start_btn" href="#">Start Over</a> */}
                </div>
              </div>
            </div>
          </div>
        </div> 
        </>
    )
}

export default Page404Error

import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { ProblemsList, AddProblems, EditProblems, SingleProblem, AddQuestions, EditQuestions, ScheduleDate, AddScheduleDate } from "./components/Problems";
import { HomePage,LandingPage,ServiceEntry,ThankyouCallback,ThankyouFeedback,ThankyouEntry,Page404Error } from "./components/Front/Pages";
import RequireAuth from "./layouts/RequireAuth";  
import PublicLayout from "./layouts/PublicLayout";  
function App() { 
  return (
    <Router>
      <Routes>

         {/* <Route path="/" element={<LandingPage />}> </Route>  */}

        { /* Without login routes.. */}    
        <Route path="/problems/:service" element={<PublicLayout><LandingPage /></PublicLayout>} />
        <Route path="/problems-questionslist/:pid" element={<HomePage />} />       
        {/* <Route path="/problems/:service/:ledgern" element={<LandingPage />} /> */}
        <Route path="/service-entry/:id" element={<ServiceEntry />} />
        <Route path="/thankyou-callback" element={<ThankyouCallback />} />
        <Route path="/thankyou-feedback/:id" element={<ThankyouFeedback />} />
        <Route path="/thankyou-entry" element={<ThankyouEntry />} />        
        {/* <Route path="/problems" element={<Page404Error />} />   */}
        <Route path="*" element={<Page404Error />} />  



        <Route path="/admin" element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path="/admin/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path="/admin/problems-list/:catId" element={<RequireAuth><ProblemsList /></RequireAuth>} />
        <Route path="/admin/add-problems/:catId" element={<RequireAuth><AddProblems /></RequireAuth>} />
        <Route path="/admin/edit-problems/:catId/:id" element={<RequireAuth><EditProblems /></RequireAuth>} />
        <Route path="/admin/single-problems-list/:catId/:id" element={<RequireAuth><SingleProblem /></RequireAuth>} />
        <Route path="/admin/add-questions/:catId/:id" element={<RequireAuth><AddQuestions /></RequireAuth>} />
        <Route path="/admin/edit-questions/:catId/:id/:problemId" element={<RequireAuth><EditQuestions /></RequireAuth>} />
        <Route path="/admin/schedule-date" element={<RequireAuth><ScheduleDate /></RequireAuth>} />
        <Route path="/admin/add-scheduledate" element={<RequireAuth><AddScheduleDate /></RequireAuth>} />
      </Routes>
    </Router>
  );

}

export default App;



import React, { useState, useEffect } from 'react'
import { useParams,useLocation,useNavigate} from 'react-router-dom'
import { findProblemByCate } from '../../../services/Front';
import  ProblemsComponets  from './ProblemsComponets';

const LandingPage = () => { 
const navigate = useNavigate();
const [isLoading,setIsLoading] = useState(false);
const [problemList,setProblemList] = useState(""); 
const params        = useParams(); 
const { search } = useLocation();
const parameters = new URLSearchParams(search); 

const ledgern = parameters.get('ledgern'); 
const serviceName   = params.service;
 
useEffect(() => { 
  if(ledgern == null){ 
  navigate("/problems", { replace: true });
  
} 
if(serviceName === ""){ 
  navigate("/problems", { replace: true });
} 
if(ledgern === ""){ 
  navigate("/problems", { replace: true });
}  
  getProblemListData();
}, [navigate])

const getProblemListData = () => {
  setIsLoading(true);
  findProblemByCate(serviceName)
    .then((result) => {
      if (result.status === 200) {
        setIsLoading(false);
        setProblemList(result.data);
      } else {
        setIsLoading(false);
        console.log('something went wrong.');
      }
    }).catch((err) => {
      setIsLoading(false); 
    });
}

return ( 
    <>  
      { isLoading ?
        <div className='loading'>
          <div className="loading_wrap">
            <div className="loading_inner">
              <p>Loading....</p>
              <div className="loading_spinner"></div>
            </div>
          </div>
        </div>
        : ""
      }  
      <ProblemsComponets problemData={problemList} ledgern={ledgern} /> 
    </>

  )
}

export default LandingPage;
import React from 'react';
import { Link } from 'react-router-dom';

const ProblemsComponets = (props) => {

  return (
    <>
      <div className="problem_listing_wrap">
        <div className="container">
          <div className="row">
            <div className="col-12 page_title">
              <h1>Welcome to Slomins <span>Customer Self Service!</span></h1>
            </div>
          </div>

          <div className="row">
            {
              props.problemData.length > 0 ? props.problemData.map((item, podidx) => {
                return (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" key={podidx}>
                    <Link to={{ pathname: '/problems-questionslist/' + item._id + '?ledgern=' + props.ledgern }}>
                      <div className="problem_blk">
                        <h3>{item.title}</h3>
                        <p>{item.summary}</p> 
                      </div>
                    </Link>
                  </div>
                )
              })
                : <h5 className='text-center'>No record found.</h5>
            }
          </div>
        </div>
      </div>
    </>

  )
}

export default ProblemsComponets;
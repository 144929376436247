import React from 'react' 
import OuterHeader from '../include/Front/OuterHeader'
import OuterFooter from '../include/Front/OuterFooter' 
import '../assets/css/service_style.css';
function PublicLayout({ children }) { 
  return  <>
      <OuterHeader/>
        <main className="landing_main" id="main">
          <div className="steps_wrap">
            {children}
          </div>
        </main> 
      <OuterFooter/>
    </>
}
export default PublicLayout;
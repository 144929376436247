import React from 'react' 
import TruckIcon from '../assets/img/truck_icon.png';
import ShIcon from '../assets/img/sh-icon.png';
import CoolingIcon from '../assets/img/cooling-icon.png';
import { Link, useLocation } from 'react-router-dom'

const Dashboard = () => {

  const { pathname } = useLocation();    
  return (
    <> 
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active"><a href="index.html">Dashboard</a></li>
        </ol>
      </nav> 
      <div className="home_category_wrap">
        <div className="home_category_inner">
          <div className="cat_item">
          <Link to={{ pathname: '/admin/problems-list/1'}} >
              <img src={ TruckIcon } alt="slomins truck" />
              <p>Heating</p>
          </Link>
          </div>
          <div className="cat_item">
            <Link to={{ pathname: '/admin/problems-list/2'}}>
                <img src={ ShIcon } alt="slomins shield" />
                <p>Security</p>
            </Link>
          </div>
          <div className="cat_item">
          <Link to={{ pathname: '/admin/problems-list/3'}}>
              <img src={ CoolingIcon } alt="Cooling" />
              <p>Cooling</p>
          </Link>
          </div>
        </div>
      </div> 
    </>
  )
}

export default Dashboard;
import React from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import tick_mark from '../../../assets/img/tick_mark.svg';
import thankyouImg from '../../../assets/img/thankyou-img.png';
const ThankyouEntry = () => {
    const params = useParams();
    const pid = params.pid;
    const { pathname } = useLocation();

    return (
        <>
            <div className="thankyou_wrap">
                <div className="container">
                    <div className="thankyou_content">
                        <div className="top_msg_wrap text-center">
                            <div className="success_circle_wrap">
                                <div className="success_circle">
                                    <img src={tick_mark} alt="ok" />
                                </div>
                            </div>
                            <div className="thankyou_msg">
                                <h2>Thank you!</h2>
                            </div>
                            <div className="order_info">
                                <p className="text-success">Your service call has been entered successfully<br /> allow us 3 to 4 hours</p>
                                <p><img src={thankyouImg} alt="thank you" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ThankyouEntry

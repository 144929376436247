import React, { useState, useEffect } from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import { addProblems } from '../../services/Auth';   
import { getToken } from '../../services/Auth';
import { toast } from "react-toastify";
import {useSelector} from 'react-redux';
const AddProblems = () => {
  const navigate = useNavigate();
  const userPermission = useSelector(state => state.user);   
  const params = useParams();
  useEffect(() => {  
    if(userPermission.user && userPermission.user.add_records == 0){
      navigate("/admin/problems-list/"+params.catId);
    }  
}, [userPermission]);

  const [isLoading, setIsLoading] = useState(false); 
  const [isDisabled, setIsDisabled] = useState(false);  
  const [isTrue, setIsTrue] = useState(false); 
  const [error, setError] = useState("");
  
  const [Ptitle, setPtitle] = useState("");
  const [PtitleError, setPtitleError] = useState("");

  const [ServicesName, setServicesName] = useState(params.catId);
  const [ServicesNameError, setServicesNameError] = useState(""); 

  const [NatureOFCall, setNatureOFCall] = useState("");
  const [NatureOFCallError, setNatureOFCallError] = useState("");

  const [PStatus, setPStatus] = useState('yes');
  const [PStatusError, setPStatusError] = useState("");

  const [callBackButton, setCallBackButton] = useState('3'); // yes for service no calback 
  const [callBackButtonError, setCallBackButtonError] = useState("");

  const [callBackText, setCallBackText] = useState("Call Back Queue"); // yes for service no calback 
  const [callBackTextError, setCallBackTextError] = useState("");

  const [serviceText, setServiceText] = useState("Service Call"); // yes for service no calback 
  const [ServiceCallTextError, setServiceCallTextError] = useState("");
 
  const [subTitle, setSubTitle] = useState("");  
  const [subTitleError, setSubTitleError] = useState("");

  const [summary, setSummary] = useState("");  
  const [summaryError, setSummaryError] = useState("");

  const [popUpText, setPopUpText] = useState("Are you sure you want to enter service call?");  
  const [popUpTextError, setPopUpTextError] = useState("");

  const [showHideServiceButton, setShowHideServiceButton] = useState(true);  
  const [showHideCallQueueButton, setShowHideCallQueueButton] = useState(true);
   
  const token = getToken();
  
  const handlePtitleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setPtitleError("First Name is required");
    } else {
      setPtitleError("");
    }
    setPtitle(val);
    setIsTrue(true);
  };

  const handleServiceNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setServicesNameError("Please select services name.");
    } else {
      setServicesNameError("");
    } 
    setServicesName(val);
    setIsTrue(true);
  }; 

  const handleCallBackTextChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setCallBackTextError("Call back queue text is required.");
    } else {
      setCallBackTextError("");
    } 
    setCallBackText(val);
    setIsTrue(true);
  };

  const handleServiceCallTextChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setServiceCallTextError("Service call text is required.");
    } else {
      setServiceCallTextError("");
    } 
    setServiceText(val);
    setIsTrue(true);
  };

  const handleSubTitleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setSubTitleError("Sub title is required.");
    } else {
      setSubTitleError("");
    } 
    setSubTitle(val);
    setIsTrue(true);
  };

  const handlePopUpTextChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setPopUpTextError("Pop up text is required.");
    } else {
      setPopUpTextError("");
    } 
    setPopUpText(val);
    setIsTrue(true);
  }; 

  const handleSummaryChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setSummaryError("Summary is required.");
    } else {
      setSummaryError("");
    } 
    setSummary(val);
    setIsTrue(true);
  }; 

  const handleNatureCallChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") { 
      setIsTrue(false);
      setNatureOFCallError("Nature of call is required.");
    } else {
      setNatureOFCallError("");
    } 
    setNatureOFCall(val);
    setIsTrue(true);
  }; 

  const handleStatusChange = e => { 
    setPStatus(e.target.value)
  }

  const handleCallBackButtonChange = e => {
    
    if(e.target.value == 3){
      setShowHideServiceButton(true); 
      setShowHideCallQueueButton(true);
    }else if(e.target.value == 2){
      //setServiceText("");
      setShowHideServiceButton(false); 
      setShowHideCallQueueButton(true);
    }else{
      //setCallBackText("");
      setShowHideServiceButton(true); 
      setShowHideCallQueueButton(false);
    }
    setCallBackButton(e.target.value)
  }
  

  const submitForm = (e) => {  
    setError("");
    e.preventDefault(); 
    if (Ptitle.length > 0 && NatureOFCall.length > 0 && subTitle.length > 0 && summary.length > 0 && popUpText.length > 0 && isTrue === true ) { 

      if(callBackButton == 3){ 
        if(serviceText.length === 0){
          setServiceCallTextError("Service call text is required."); 
          return false; 
        }
        else if(callBackText.length === 0){
          setCallBackTextError("Call back queue text is required.");
          return false; 
        }

      }else if(callBackButton == 1){
          if(serviceText.length === 0 ){
            setServiceCallTextError("Service call text is required.");
            return false;  
          }        
      }else if(callBackButton == 2){
        if(callBackText.length === 0){
          setCallBackTextError("Call back queue text is required.");
          return false;
        }
      } 

      setIsLoading(true);

      
      const data = {
        title: Ptitle, 
        category: params.catId,
        nature_of_call: NatureOFCall, 
        subTitle : subTitle,
        summary : summary,
        popUpText : popUpText,
        callBackButton : callBackButton,
        serviceButtonText : serviceText,
        callBackQueueButtonText : callBackText,
        slug : params.catId === '1' ? 'heating-services' : params.catId === '2' ? 'security-services' :'cooling-services',
        status: (PStatus == 'yes' ? 'Active' : 'InActive' )
      };
      addProblems(data,token)
        .then((result) => {
          setIsDisabled(false);
          setIsLoading(false);
          setPtitleError("");  
          if (result.status === 200) {
            toast.success('Problem added successfully.');
            navigate("/admin/problems-list/"+params.catId); 
          } else {
            setIsLoading(false);
            toast.error("Problem couldn't added Please try again."); 
          }
        })
        .catch((err) => {
          setError("Error in add problems");
          setIsLoading(false);
        });
    } else { 
      setIsDisabled(false);
      setIsLoading(false); 
      if (Ptitle.length === 0) {
        setPtitleError("Problems is required");
      } 
      if (ServicesName.length === 0 ) {
        setServicesNameError("Services name is required");
      }
      if (NatureOFCall.length === 0 ) {
        setNatureOFCallError("Nature OF call is required");
      }
      if (subTitle.length === 0 ) {
        setSubTitleError("Sub Title is required");
      }
      if (summary.length === 0 ) {
        setSummaryError("Summary is required");
      }
      if (popUpText.length === 0 ) {
        setPopUpTextError("Pop up text is required");
      }
      
      if (PStatus.length === 0 ) {
        setPStatusError("Status is required");
      } 
      return false;
    }
  }; 

  return (
    <> 
     {isLoading ?
          <div className='loading'>
            <div className="loading_wrap">
            <div className="loading_inner">
              <p>Loading....</p>
              <div className="loading_spinner"></div>
            </div>
          </div> 
        </div> 
        : ""
      }   
      <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>   
            <li className="breadcrumb-item"><Link className="breadcrumb-item" to={{ pathname: `/admin/problems-list/${params.catId}`}}>{
                params.catId === '1' ? 'Heating Services' : params.catId === '2' ? 'Security Services' :'Cooling Services'
            }</Link></li>
            <li className="breadcrumb-item active"><a href="#">New Problem</a></li>
          </ol>
      </nav>

    <div className="add_new_prob_wrap">
      <div className="add_new_prob_inner">
        <h2>Add New Problem</h2>
        <form onSubmit={submitForm}>
          <div className="form-group">
            <label htmlFor="inputTitle">Title</label>
            <input type="text" name="title" className="form-control" id="inputTitle" placeholder="Problem title" value={Ptitle} onChange={handlePtitleChange} />
            {PtitleError && ( <p className="text-danger text-gradient small mb-2">{PtitleError}</p>)}
            
          </div>
          <div className="form-group">
            <select className="form-control" name="category" value={ServicesName} onChange={handleServiceNameChange}>
              <option value="1">Heating Services</option>
              <option value="2">Security Services</option>
              <option value="3">Cooling Services</option>
             </select>
             {ServicesNameError && ( <p className="text-danger text-gradient small mb-2">{ServicesNameError}</p>)}
          </div>
          <div className="form-group">
            <label htmlFor="inputNatureCall">Nature of call code</label>
            <input type="text" className="form-control" name="nature_of_call" id="inputNatureCall" placeholder="Nature of call code" 
             onChange={handleNatureCallChange} />
            {NatureOFCallError && ( <p className="text-danger text-gradient small mb-2">{NatureOFCallError}</p>)}
          </div>

          <div className="form-group">
            <label htmlFor="inputNatureCall">Sub Title</label>
            <input type="text" className="form-control" name="Sub Title" id="inputNatureCall" placeholder="Sub Title" 
             onChange={ handleSubTitleChange } />
            {subTitleError && ( <p className="text-danger text-gradient small mb-2">{subTitleError}</p>)}
          </div>

          <div className="form-group">
            <label htmlFor="inputNatureCall">Summary</label>
            <textarea className="form-control" name="Summary" id="inputNatureCall" value={summary} placeholder="Summary" onChange={ handleSummaryChange } rows="4" cols="50"></textarea>
            {summaryError && ( <p className="text-danger text-gradient small mb-2">{summaryError}</p>)}
          </div>

          <div className="form-group">
            <label htmlFor="inputNatureCall">Pop Up Text</label>
            <input type="text" className="form-control" name="pop_up_text" id="inputNatureCall" value={popUpText} placeholder="Pop Up Text" onChange={ handlePopUpTextChange } />
            {popUpTextError && ( <p className="text-danger text-gradient small mb-2">{popUpTextError}</p>)}
          </div>

          <div className="form-group">
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="activeRadio" name="status" className="custom-control-input" checked={PStatus === 'yes'} onChange={handleStatusChange} value='yes' />
              <label className="custom-control-label" htmlFor="activeRadio">Active</label> 
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="inactiveRadio" name="status" className="custom-control-input" checked={PStatus === 'no'} onChange={handleStatusChange} value='no'/>
              <label className="custom-control-label" htmlFor="inactiveRadio">Inactive</label>
            </div>
            {PStatusError && ( <p className="text-danger text-gradient small mb-2">{PStatusError}</p>)}
          </div>

           <div className="form-group">
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="activeRadio1" name="callBackButton" className="custom-control-input" checked={callBackButton === '1'} onChange={ handleCallBackButtonChange } value='1' />
              <label className="custom-control-label" htmlFor="activeRadio1">Service Call button</label> 
            </div>

            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="inactiveRadio1" name="callBackButton" className="custom-control-input" checked={callBackButton === '2'} onChange={ handleCallBackButtonChange } value='2'/>
              <label className="custom-control-label" htmlFor="inactiveRadio1">Call Back Queue button</label>
            </div> 
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="inactiveRadio2" name="callBackButton" className="custom-control-input" checked={callBackButton === '3'} onChange={ handleCallBackButtonChange } value='3'/>
              <label className="custom-control-label" htmlFor="inactiveRadio2">Both</label>
            </div> 
            {callBackButtonError && ( <p className="text-danger text-gradient small mb-2">{callBackButtonError}</p>)}
          </div> 
          {
            showHideServiceButton == true ? 
          <div className="form-group">
            <label htmlFor="inputNatureCall">Service call button text</label>
            <input type="text" className="form-control" name="service_call" id="inputNatureCall" placeholder="Service call button label" value={serviceText}
             onChange={handleServiceCallTextChange} />
            {ServiceCallTextError && ( <p className="text-danger text-gradient small mb-2">{ServiceCallTextError}</p>)}
          </div>
          :''
        }

        {
          showHideCallQueueButton == true ?  
          <div className="form-group">
            <label htmlFor="inputNatureCall">Call back queue button text</label>
            <input type="text" className="form-control" name="callBackText" id="inputNatureCall" placeholder="Call back queue button label" value={callBackText} 
             onChange={handleCallBackTextChange} />
            {callBackTextError && ( <p className="text-danger text-gradient small mb-2">{callBackTextError}</p>)}
          </div>
          :""
        } 
          <button type='submit' className="btn btn-primary btn_red_sm" disabled={isDisabled}>
            {isDisabled ? "Submitting..." : "Save"}
          </button>
        </form>
      </div>
    </div> 
    </>
  )
}

export default AddProblems;
import React, { useState, useEffect, useMemo } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { getProblemsList, removeProItem, updatePostion,sendLinkToCustomer,getToken } from '../../services/Auth'; 
import { constant } from '../../services/baseUrl';
import PositionComponets from '../Problems/PositionComponets';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Modal } from "react-bootstrap";
import InputMask from 'react-input-mask';
import moment from 'moment';
import { TableHeader, Pagination, Search } from "../DataTable"; 
import {useSelector} from 'react-redux'; 
const ProblemsList = () => { 
  const FRONT_URL  = constant.FRONT_URL;  
  const userPermission = useSelector(state => state.user);  
  const paramId = useParams();
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const token = getToken();    
  useEffect(() => {  
    
  }, [pathname]);   
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" }); 
  const ITEMS_PER_PAGE = 15; 
  const [isLoading, setIsLoading] = useState(false);
  const [getProblems, setGetProblems] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
 
  const [error, setError] = useState("");
  const [problmId, setProblmId] = useState("");
  const [ledgerN, setLedgerN] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [ledgerNError, setLedgerNError] = useState("");
  const [copyType, setCopyType] = useState(""); 

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (problmId, ctype) => {
    setLedgerNError("");
    setPhoneNumberError("");
    setProblmId(problmId);
    setCopyType(ctype);
    setShow(true)
  };

  const handleInput = ({
    target: { value }
  }) => setPhoneNumber(value);


  const headers = [
    { name: "Id", field: "id", sortable: false },
    { name: "Problem Title", field: "title", sortable: true },
    { name: "Status", field: "status", sortable: true },
    { name: "Position", field: "position", sortable: false },
    { name: "Created At", field: "created_at", sortable: true },
    { name: "Actions", field: "actions", sortable: false }
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        comment =>
          comment.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);
    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting]);


  const handleLedgerNChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setLedgerN(val);
  };   

  useEffect(() => {
    setSearch(""); 
    getProblemsData();  
  }, [pathname])
 
  const copyHandler = (probId, cpType) => {
    setPhoneNumberError("");
    setPhoneNumber("");
    if (ledgerN === '') {
      setLedgerNError("Ledger number is required")
    } else {
      if (cpType == 1) {
        if (splitLocation[3] == 1) {
          var serviceName = 'heating-services';
        } else if (splitLocation[3] == 2) {
          var serviceName = 'security-services';
        } else {
          var serviceName = 'cooling-services';
        }
        var problemCatUrl = FRONT_URL + 'problems/' + serviceName + '?ledgern=' + ledgerN;
      } else {
        var problemCatUrl = FRONT_URL + 'problems-questionslist/' + probId + '?ledgern=' + ledgerN;
      }
      setLedgerNError("");
      const el = document.createElement("input");
      el.value = problemCatUrl;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      toast.success('Link Copy Successfull');
    }
  }

  const sendSMSHandler = (probId, cpType) => {
    setLedgerNError("");
    setPhoneNumberError(""); 
    var phone = phoneNumber;
    phone = phone.replace(" ", "");
    phone = phone.replace("(", "");
    phone = phone.replace(")", "");
    phone = phone.replace("-", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");
    phone = phone.replace("_", "");

    if (phone == '' && ledgerN === '') {
      setPhoneNumberError("Phone number is required");
      setLedgerNError("Ledger number is required");
      return false;
    } else if (ledgerN === '') {
      setLedgerNError("Ledger number is required");
      return false;
    } else if (phone == '') {
      setPhoneNumberError("Phone number is required");
    } else if (phone.length < 10) {
      setPhoneNumberError("Phone number should be 10 digit number.");
      return false;
    } else {
      if (cpType == 1) {
        if (splitLocation[3] == 1) {
          var serviceName = 'heating-services';
        } else if (splitLocation[3] == 2) {
          var serviceName = 'security-services';
        } else {
          var serviceName = 'cooling-services';
        }
        var problemCatUrl = FRONT_URL + 'problems/' + serviceName + '?ledgern=' + ledgerN;
      } else {
        var problemCatUrl = FRONT_URL + 'problems-questionslist/' + probId + '?ledgern=' + ledgerN;
      }
      setLedgerNError("");  

      setIsDisabled(true); 
      const postData = { 
        'problemUrl' : problemCatUrl, 
        'phoneNumber' : phone, 
      }; 
       // call api for inquery.
       sendLinkToCustomer(postData,token)
       .then((result) => {  
           if (result.status === 200) {
               setIsDisabled(false);
               setIsLoading(false); 
               if(result.data.Acknowledge ===1){   
                setLedgerN("");
                setPhoneNumber(""); 
                 toast.success('Success!! Sent SMS to customer successfully.');
               }else{ 
                setLedgerN("");
                setPhoneNumber(""); 
                toast.error(result.data.message);
               } 
           } else {
               setIsDisabled(false);
               setIsLoading(false);
               toast.error("Something went wrong.");
           }
       })
       .catch((err) => {
           setIsDisabled(false);
           toast.error("Something went wrong.");
           setIsLoading(false);
       });

    }
  }

  const submitPostion = (position, proIds) => {
    setIsLoading(true);
    const data = {
      proIds: proIds,
      position: position,
    };
    updatePostion(data, token)
      .then((result) => {
        setIsDisabled(false);
        setIsLoading(false);
        if (result.status === 200) {
          getProblemsData();
          toast.success('Position updated successfully.');
        } else {
          setIsLoading(false);
          toast.error("position couldn't added Please try again.");
        }
      })
      .catch((err) => {
        setError("Error in update position");
        setIsLoading(false);
      });
  }

  const getProblemsData = () => {
    setIsLoading(true);
    getProblemsList(splitLocation[3], token)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setGetProblems(result.data);
          setComments(result.data);
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
      });
  }


  const handleClickDeleteItem = (paramId) => {
    confirmAlert({
      // title: 'Confirm Alert!.',
      message: 'Are you sure you want to delete this problem?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsLoading(true);
            removeProItem(paramId, token)
              .then((result) => {
                setIsLoading(false);
                if (result.data.status === 200) {
                  getProblemsData();
                  toast.success(result.data.message);
                } else {
                  toast.error("Problem couldn't deleted Please try again.");
                }
              }).catch((err) => {
                toast.error("Something wrong");
              });
          }

        },
        {
          label: 'No',
        }
      ]
    });
  } 
  return (
    <>
      {isLoading ?
        <div className='loading'>
          <div className="loading_wrap">
            <div className="loading_inner">
              <p>Loading....</p>
              <div className="loading_spinner"></div>
            </div>
          </div>
        </div>
        : ""
      }
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={{ pathname: `/admin/dashboard` }}>Dashboard</Link></li>
          <li className="breadcrumb-item">
            <Link to={{ pathname: `/admin/problems-list/${paramId.catId}` }}>Problems</Link></li>
          <li className="breadcrumb-item active">
            <Link to={{ pathname: `/admin/problems-list/${paramId.catId}` }}>{
              paramId.catId === '1' ? 'Heating Services' : paramId.catId === '2' ? 'Security Services' : 'Cooling Services'
            }</Link></li>
        </ol>
      </nav> 

      <div className="all_prob_wrap">
        <div className="all_prob_inner">
          <div className="table_title d-flex">
            <h2>{
              paramId.catId === '1' ? 'Heating Services' : paramId.catId === '2' ? 'Security Services' : 'Cooling Services'
            }</h2> 
            { 
             userPermission.user && userPermission.user.add_records == 1 ? 
            <Link className="btn btn-primary btn_add_prob" to={{ pathname: `/admin/add-problems/${paramId.catId}` }} disabled="disabled">Add New Problem
            </Link>  
             :"" 

            }

            { 
             userPermission.user && userPermission.user.copy_link == 1 ? 
            <button type="button" className="btn btn-primary btn_copy_link_prob" data-toggle="modal" data-target="#serviceCallModal" variant="primary" onClick={() => handleShow(paramId.catId === '1' ? 'Heating Services' : paramId.catId === '2' ? 'Security Services' : 'Cooling Services', 1)} >Send Link</button>
             :"" 

            }
            &nbsp;

          </div>

          <div className='table-warp-search-pagination'>
            <div className='d-flex custom-pagination justify-content-between'>
              <Search
                onSearch={value => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={page => setCurrentPage(page)}
              />
            </div>
            <table className="cell-border table table-hover table-bordered table-striped postionInput" style={{ width: '100%' }} >
              <TableHeader
                headers={headers}
                onSorting={(field, order) =>
                  setSorting({ field, order })
                }
              />
              <tbody>
                {commentsData.length > 0 ? commentsData.map((proItem, podidx) => (
                  <tr key={podidx} >
                    <td>{proItem._id}</td>
                    <td>{proItem.title}</td>
                    <td>{proItem.status}</td>
                    <td>
                      <PositionComponets submitPostion={submitPostion} proIds={proItem._id} position={proItem.position} positionPermissin={userPermission.user && userPermission.user.edit_records == 1 ? 1 :0 } />
                    </td>
                    <td>{moment(proItem.created_at).format("MM-DD-YYYY")}</td>
                    <td>
                    { 
                       userPermission.user && userPermission.user.edit_records == 1 ? 
                      <Link className="btn btn-primary btn_red_extsm edit_btn"
                        to={{ pathname: `/admin/edit-problems/${paramId.catId}/${proItem._id}` }} title="Edit"><i className="fa fa-edit"></i></Link>
                      :""
                    }
                    { 
                      userPermission.user && userPermission.user.view_records == 1 ? 
                      <Link className="btn btn-primary btn_red_extsm view_btn" to={{ pathname: `/admin/single-problems-list/${paramId.catId}/${proItem._id}` }} title="View"><i className="fa fa-eye"></i></Link>
                      :""
                    }
                    { 
                      userPermission.user && userPermission.user.delete_records == 1 ?
                      <button onClick={() => handleClickDeleteItem(proItem._id)} title="Delete" className="btn btn-primary btn_red_extsm delete_btn"><i className="fa fa-trash"></i></button>
                      :""
                    }
                    { 
                     userPermission.user && userPermission.user.copy_link == 1 ? 
                      <button type="button" className="btn btn-primary btn_red_extsm edit_btn" data-toggle="modal" data-target="#serviceCallModal" variant="primary" onClick={() => handleShow(proItem._id, 2)} ><i className="fa fa-phone"></i></button>
                      :""
                    }
                    </td>
                  </tr>
                ))
                  : <tr><td style={{ textAlign: 'center' }} colSpan="6">No record found.</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton variant="white"> Copy Link { 
               userPermission.user && userPermission.user.send_sms == 1 ?  "& Send SMS" :""}</Modal.Header>
        <Modal.Body>
          <div className="popup_msg_wrap d-flex copyLagerN" >
            <div className="msg row row-cols-lg-auto ">
              <div className="form-group">
                <label>Enter ledger number</label>
                <input name="ledgerNumber" onChange={handleLedgerNChange} className='form-control' placeholder='Enter ledger number' value={ledgerN} />
                {ledgerNError && (<p className="text-danger text-gradient small mb-2">{ledgerNError}</p>)}
              </div>

            </div>
          </div>
          { 
             userPermission.user && userPermission.user.send_sms == 1 ? 
          <div className="popup_msg_wrap d-flex copyLagerN" >
            <div className="msg row row-cols-lg-auto ">
              <div className="form-group">
                <label>Enter mobile number</label>
                <InputMask
                  mask='(999) 999-9999'
                  value={phoneNumber}
                  placeholder='Phone Number'
                  className="form-control"
                  onChange={handleInput}>
                </InputMask>
                {phoneNumberError && (<p className="text-danger text-gradient small mb-2">{phoneNumberError}</p>)}
              </div>

            </div>
          </div>
           :""
          }
        
          <div className="popup_msg_wrap d-flex copyLagerN" >
            <div className="msg row row-cols-lg-auto"> 
              <div className='copyBtnWarp'>
                <button className="btn btn-primary btn_red_extsm copyLagernPopup" title="Copy url" onClick={(e) => copyHandler(problmId, copyType)}>Copy Link</button>
              </div>
              { 
               userPermission.user && userPermission.user.send_sms == 1 ? 
              <div className='copyBtnWarp sendSMS'>
                <button className="btn btn-primary btn_red_extsm copyLagernPopup sendSMSButton" title="Copy url"
                  onClick={(e) => sendSMSHandler(problmId, copyType)} disabled={isDisabled}>
                  {isDisabled ? "Sending..." : "Send SMS"}</button>
              </div>
              :""
            }
            </div>
          </div>
          

        </Modal.Body>
      </Modal>

    </>
  )
}

export default ProblemsList;
import React, { useState, useEffect } from 'react'
import {Link,useNavigate,useParams } from 'react-router-dom' 
import { UpdateQuestion,getQuestionView ,getProblemsView} from '../../services/Auth'; 
import loadingImg from '../../assets/img/animated.svg';
import { getToken } from '../../services/Auth';
import { toast } from "react-toastify";

const EditQuestions = () => {
  const navigate = useNavigate();
  const params = useParams(); 
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false); 
  const [isTrue, setIsTrue] = useState(false);
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [Qtitle, setQtitle] = useState("");
  const [QtitleError, setQtitleError] = useState("");
 
  const [Summary, setSummary] = useState("");
  const [SummaryError, setSummaryError] = useState(""); 
  
  const [FeedbackTitle, setFeedbackTitle] = useState("");
  const [FeedbackTitleError, setFeedbackTitleError] = useState("");

  const [VideoTitle, setVideoTitle] = useState(""); 

  const [QStatus, setQStatus] = useState("");
  const [QStatusError, setQStatusError] = useState(""); 
 
  const [getQuestion, setGetQuestion] = useState(""); 
  const [problemId, setProblemId] = useState(params.problemId); 
  const token = getToken(); 
 
  const handleQtitleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setIsTrue(false);
      setQtitleError("Question title is required");
    } else {
      setQtitleError("");
    }
    setQtitle(val);
    setIsTrue(true);
  };

  const handleFeedbackTitleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setIsTrue(false);
      setFeedbackTitleError("Please enter feedback title.");
    } else {
      setFeedbackTitleError("");
    }
    setFeedbackTitle(val);
    setIsTrue(true);
  };  

  const handleVideoTitleChange = (e) => {
    e.preventDefault();
    const val = e.target.value; 
    setVideoTitle(val);
    setIsTrue(true);
  };

  const handleSummaryChange = (e) => { 
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setIsTrue(false);
      setSummaryError("Summary is required.");
    } else {
      setSummaryError("");
    }
    setSummary(val);
    setIsTrue(true);
  }; 

  useEffect(() => {  
    getSingleTitle();
    getQuestionSingal();  
 }, []) 


 const getSingleTitle = () => {   ;
  const pIds = {
        id:problemId
    }; 
  getProblemsView(pIds,token)
    .then((result) => {
      if (result.status === 200) {
        setIsLoading(false);
        setTitle(result.data.title);
      } else {
        setIsLoading(false);
      }
    }).catch((err) => {
      setIsLoading(false);
    });
}

  const getQuestionSingal = () => {
    setIsLoading(true);  
    getQuestionView(params,token)
      .then((result) => {
        if (result.status === 200) {
            setIsLoading(false);
            setGetQuestion(result.data);
            setQtitle(result.data.questions_title);
            setFeedbackTitle(result.data.feedback_title);
            setSummary(result.data.summary);  
            setVideoTitle(result.data.video_url);   
            setQStatus(result.data.status == true ? 'yes':'no');
            setIsTrue(true);
        } else {
          setIsLoading(false);
          toast.error(result.message); 
        } 
      }).catch((err) => {
        setIsLoading(false); 
        toast.error('Token is required for authentication!');
      });
  }
 
  const submitForm = (e) => { 
    setError("");
    setIsDisabled(true);
    e.preventDefault(); 
    if (Qtitle.length > 0 && FeedbackTitle.length > 0 && Summary.length > 0 && isTrue === true) {
      setIsLoading(true); 

      if(VideoTitle !==""){
      const checkTypeOfVideo = VideoTitle.split('https://www.youtube.com/')[1];
      var firstC = checkTypeOfVideo.split('embed/')[1]; 

      if(firstC === undefined){
        const domainUrl   =  VideoTitle.split('watch?v=')[0];
        const youTubeCode = VideoTitle.split('watch?v=')[1];
        var mainUrl = domainUrl+'embed/'+youTubeCode
      }else{
        var mainUrl = VideoTitle;
      }
    }else{
      var mainUrl = "";
    }


      const data = {
        id: params.id, 
        questions_title: Qtitle,
        summary: Summary,
        feedback_title: FeedbackTitle, 
        video_url: mainUrl,
        problem_id:problemId,
        status: (QStatus == 'yes' ? true : false)
      };  
      UpdateQuestion(data, token)
        .then((result) => {
          setIsDisabled(false);
          setIsLoading(false);
          setQtitleError("");
          if (result.status === 200) {
            toast.success('Question have been updated successfully.');
            navigate("/admin/single-problems-list/"+params.catId+'/'+params.problemId);
          } else {
            setIsLoading(false);
            toast.error("Question couldn't updated Please try again.");
          }
        })
        .catch((err) => {
          setError("Error in edit question");
          setIsLoading(false);
        });
    } else {
      setIsDisabled(false);
      setIsLoading(false);
      if (Qtitle.length === 0) {
        setQtitleError("Question title is required");
      } 
      if (FeedbackTitle.length === 0) {
        setFeedbackTitleError("Feedback title is required");
      } 
      if (Summary.length === 0) {
        setSummaryError("Question summary is required");
      }
      
      if (QStatus.length === 0) {
        setQStatusError("Status is required");
      }
      return false;
    }
  };

  return (
    <>

        {isLoading ?
              <div className='loading'>
                <div className="loading_wrap">
                <div className="loading_inner">
                  <p>Loading....</p>
                  <div className="loading_spinner"></div>
                </div>
              </div> 
            </div> 
            : ""
        }   
        
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
            <li className="breadcrumb-item"><Link className="breadcrumb-item" to={{ pathname: `/admin/problems-list/${params.catId}`}}>{
                params.catId === '1' ? 'Heating Services' : params.catId === '2' ? 'Security Services' :'Cooling Services'
            }</Link></li>
            
            <li className="breadcrumb-item">
              <Link className="breadcrumb-item" to={{ pathname: `/admin/single-problems-list/${params.catId}/${problemId}`}}>{title}</Link></li>
            <li className="breadcrumb-item active"><a href="#">Edit Question</a></li>
          </ol>
        </nav>
        <div className="add_new_question_wrap">
          <div className="add_new_question_inner">
            <h2>Update Question</h2>
            <form onSubmit={submitForm}>
              <div className="form-group">
                <label htmlFor="inputQusTitle">Question Title</label> 
                <input type="text" className="form-control" id="inputQusTitle" placeholder="Question Title" value={Qtitle} onChange={handleQtitleChange} />
            {QtitleError && ( <p className="text-danger text-gradient small mb-2">{QtitleError}</p>)}
              </div>
              <div className="html_editor form-group">
                <label>Question Summary</label>
                <textarea id="ck_editor" className="form-control" value={Summary} onChange={handleSummaryChange} placeholder="Question Summary"></textarea>
                {SummaryError && ( <p className="text-danger text-gradient small mb-2">{SummaryError}</p>)}
              </div>
 
              <div className="form-group">
                <label htmlFor="inputFeedback">Feedback Title</label>
                <input type="text" className="form-control" id="inputFeedback" placeholder="Feedback title" value={FeedbackTitle} onChange={handleFeedbackTitleChange} />
            {FeedbackTitleError && ( <p className="text-danger text-gradient small mb-2">{FeedbackTitleError}</p>)}
              </div>
              <div className="form-group">
                <label htmlFor="inputVideoUrl">Enter Video URL</label>
                <input type="text" className="form-control" id="inputVideoUrl" placeholder="Enter Youtube or Vimeo video URL" value={VideoTitle} onChange={handleVideoTitleChange} /> 
              </div>  
              <button type='submit' className="btn btn-primary btn_red_sm" disabled={isDisabled}>
                          {isDisabled ? "Updating..." : "Update"}
                        </button>
            </form>
          </div>
        </div> 
    </>
  )
}

export default EditQuestions;